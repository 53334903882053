import { makeStyles } from '@material-ui/core';
import React from 'react'
import Help from '../Help';
import { useTitle } from '../useTitle';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex'
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  },
  wrapper: {
    alignItems: 'start',
    textAlign: 'left',
    textTransform: 'none',
    fontSize: '1.14em'
  },
  helpCt: {
    display: 'flex',
    position: 'absolute',
    top: 70,
    right: 0,
    zIndex: 100000
  },
  helpButton: {
    marginLeft: 'auto'
  },
  help: {
    cursor: 'pointer',
    paddingTop: '8px',
    margin: 'auto 5px auto auto',
    '&:hover': {
      color: 'rgb(128,128,128)'
    }
  }
}));

const Mechanisms = () => {
  useTitle("Pathogenic Mechanisms")
  const classes = useStyles()

  return <>
    <div ><script src="/flourish.embed.js"></script></div>
    <div className={classes.helpCt}>
      <Help
        className={classes.help}
        title={'Pathogenic Mechanisms'}
        body={<>
          A network plot of entities affected by the SARS-CoV-2 virus infection, showing organs, symptoms, mechanisms (systems, pathways, and genes), signaling axes (ACE2) and signaling systems (CAS) <br /><br />
          <span style={{ fontSize: '0.8em' }}>* To avoid overcrowding the plot, general effects such as “inflammation” are omitted.</span><br /><br />
          To open a detailed model in a separate browser tab, click on an entity in the plot, then click the underlined entity name.<br /><br />
          To hide a category of entities, click on the category name in the bottom left of the screen. Click again to show the entities.
          </>}
      />
    </div>
    <iframe title={'Pathogenic Mechanisms'} scrolling="no" src={'mechs.html'} style={{ height: 'calc(100% - 8px)', width: '100%', overflow: 'hidden' }} frameBorder="0">

    </iframe>
  </>

}

export default Mechanisms