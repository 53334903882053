import { unstable_createMuiStrictModeTheme as createMuiTheme, CssBaseline, makeStyles, Theme, ThemeProvider } from '@material-ui/core';
import React from 'react';
import AppRouter from './Router';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    minHeight: '100vh'
  },
}));

function App() {
  const classes = useStyles();

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#2d72dc'//'#253746',
      },
      secondary: {
        main: '#2d72dc',// '#00778b',
      },
    },
    typography: {
      fontFamily: [
        'Source Sans Pro', 'sans-serif'
      ].join(','),
    },
  });
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <AppRouter />
        </div>
      </ThemeProvider>
    </>
  );
}

export default App;
