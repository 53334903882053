import React from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import SubmodelDetails from './Submodels/SubmodelDetails'
import MainNavigation from './MainNavigation'
import { FULL_BUILD } from './build'

const AppRouter = () => {
  return (
    <Router>
      <Switch>
        <Route path='/interactions' component={MainNavigation} />
        <Route path='/symptoms' component={MainNavigation} />
        <Route exact path='/publications' component={MainNavigation} />
        <Route exact path='/cellmodel' component={MainNavigation} />
        <Route path='/submodels' component={MainNavigation} />
        <Route exact path='/basemodel' component={MainNavigation} />
        <Route exact path='/glossary' component={MainNavigation} />
        {
          FULL_BUILD ? <Route path='/submodels/:id' component={SubmodelDetails} /> : <Redirect path='/submodels/:id' to='/' />
        }
        <Route path='/' component={MainNavigation} />
      </Switch>
    </Router>
  )
}

export default AppRouter
