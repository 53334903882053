import { TableCell, TableHead, TableRow, TableSortLabel } from "@material-ui/core";
import React from "react";
import GeneIcon from "./Icons/GeneIcon";
import ModelIcon from "./Icons/ModelIcon";
import SymptomIcon from "./Icons/SymptomIcon";
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import DiseaseIcon from "./Icons/DiseaseIcon";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export type Order = 'asc' | 'desc';

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
  classes: any;
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  order: Order;
  orderBy: string;
  headCells: string[];
  showCounters?: boolean;
  rows?: any;
}

function findKey(label: string) {
  const keyLabels = [
    {
      key: 'name',
      label: 'Mechanisms'
    },
    {
      key: 'organs',
      label: 'Organs'
    },
    {
      key: 'symptoms',
      label: 'Symptoms / Syndromes'
    },
    {
      key: 'genes',
      label: 'Genes'
    },
    {
      key: 'references',
      label: 'Publications'
    }
  ];
  const elem = keyLabels.find(keyLabel => keyLabel.label === label);
  if (elem) {
    return elem.key;
  }
}

function getUniqueCount(rows: any, headCell: string) {
  const key = findKey(headCell)
  if (key) {
    // HACK is using split by comma
    const concepts = rows.map((row: any) => row[key] ? row[key].split(', ') : row[key]).flat()
      .filter((concept: any) => !!concept);
    const uniqueConcepts = Array.from(new Set(concepts));
    return uniqueConcepts.length;
  }
}

function TableHeader(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort, headCells, rows, showCounters } = props;

  const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell}
            align={'left'}
            style={{ whiteSpace: 'nowrap' }}
            sortDirection={orderBy === headCell ? order : false}
          >
            {headCell !== "Download" ?
              <TableSortLabel
              active={orderBy === headCell}
              direction={orderBy === headCell ? order : 'asc'}
              onClick={createSortHandler(headCell)}
            >
              {(headCell === 'Disease' || headCell === 'Diseases') && <DiseaseIcon size={24} />}
              {(headCell === 'Organs' || headCell === 'Organ') && <AccessibilityIcon />}
              {headCell === 'Genes' && <GeneIcon size={24} />}
              {(headCell.trim() === 'Symptoms / Syndromes' || headCell === 'Symptom / Syndrome') && <SymptomIcon size={24} />}
              {(headCell === 'Mechanisms' || headCell === 'Models' || headCell === 'Mechanism') && <ModelIcon size={24} />}
                <strong style={{ fontSize: '1.13em' }}>
                  {headCell !== "Download" ? headCell : ""}
                </strong>
              {orderBy === headCell ? (
                <span style={{ visibility: 'collapse', width: '0' }}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
              { showCounters && headCell !== "Download" &&
                <strong>
                &nbsp;({getUniqueCount(rows, headCell)})
                </strong>
              }
            </TableSortLabel>
            : ""
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default TableHeader
