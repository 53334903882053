import { makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(() => ({
  icon: {
    marginRight: '4px',
    ":hover": {
      filter: "brightness(80%)"
    }
  }
}))

const DiseaseIcon = ({ size }: { size: number }) => {
  const classes = useStyles()

  return (
    <img alt="diseases column" src="Disease Icon.svg" style={{ width: `${size}px`, height: `${size - 4}px` }} className={classes.icon} />
  )
}

export default DiseaseIcon