import React from 'react'
import { Fade } from '@material-ui/core';
import useWindowHeight from './useWindowHeight';

export interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const height = useWindowHeight()

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{
        height: (height - 68) + 'px',
        overflowY: 'auto'
      }}
      {...other}
    >
      {value === index && (
        <Fade in timeout={300} style={{ width: '100%', height: '100%' }}>
          <div>
            {children}
          </div>
        </Fade>
      )}
    </div>
  );
}

export default TabPanel
