import React, { useState } from 'react'
import { Container, Typography, Button, ListItem, List, Hidden, useMediaQuery } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import useWindowHeight from './useWindowHeight'
import { useTheme } from '@material-ui/core/styles'

const Overview = () => {
  const history = useHistory()
  const [showMore, setShowMore] = useState(false)
  const height = useWindowHeight()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))

  return (<div>
    <div style={{ height: (height - 64) + 'px', display: 'flex', flexDirection: 'column', overflowY: 'auto', backgroundImage: 'url("/landing_bg.png")', backgroundSize: 'cover', color: 'white', zIndex: 2, marginTop: '-4px'}}>
      <Hidden smDown>
        <div style={{ position: 'relative'}}>
          <div style={{ position: 'absolute', width: '80%', minHeight: (height - 92) + 'px', margin: '0 10%', backgroundImage: 'url("/virus_background.png")', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', color: 'white', pointerEvents: 'none', zIndex: 3}}></div>
        </div>
      </Hidden>
      <Container maxWidth='sm' style={matches ? {marginLeft: '12.5%', zIndex: 4 } :  { zIndex: 4 }} >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Typography variant={'h3'} align={'left'} style={{ paddingBottom: '8px', margin: (height / 3.5) + 'px 0 0 0', fontWeight: 'bold' }}>MH Corona Explorer</Typography>
        </div>
        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        Understanding SARS-CoV-2: Organs & Symptoms and related model mechanisms for virus infection and diseases
        </Typography>
        <div style={{ width: '100%' }}>
          <Button onClick={() => history.push('/symptoms')} style={{ background: 'linear-gradient(100deg,#00778b 0%,#97d700 100%)', borderRadius: '32px', textTransform: 'none', color: 'white', margin: '16px 0 32px', paddingLeft: '48px', paddingRight: '48px', fontSize: '1.2em' }}>Start exploring</Button>
        </div>
        <Typography variant={'body2'} style={{ margin: '0', marginTop: '5rem', textAlign: 'justify' }}>
          This COVID-19 disease model was developed in a collaboration between the translational research organization of the Institute of Regenerative Medicine of the Zürich University and Molecular Health GmbH, Heidelberg, experts in data-driven precision medicine.
          </Typography>
        { !showMore && 
        
        <div style={{ width: '100%' }}>
        <Button variant={'outlined'} style={{ borderRadius: '32px',borderColor: '#00778b', textTransform: 'none', color: 'white', margin: '16px 0 32px', paddingLeft: '48px', paddingRight: '48px', fontSize: '1.2em' }} onClick={() => setShowMore(true)}>About the project</Button></div>}
        { showMore && (
        <>
        <Typography style={{  }}>
        <br /><br />
          The disease model is based on the Augmented Clinico-Molecular Disease Modeling technology (aCMDM), linking key molecular entities to COVID-19 pathophysiology and to disease symptoms (e.g., dry cough, myalgia, anosmia, dysgeusia/ageusia, new onset diabetes, etc.), severe manifestations (e.g., ARDS, ALI, lung fibrosis, cardiovascular complications), and outcome- and severity-associated risk factors (e.g., age, sex, smoking, comorbidities).<br /><br />
        
        For further details see <strong>“Unraveling the Multiple Manifestations of COVID-19 using Augmented Clinico-Molecular Disease Modeling”</strong> (submitted for publication) or contact us at <a style={{ textDecoration: 'none', color: 'rgb(128,128, 256)', fontWeight: 'bold'}} href="mailto:corona@molecularhealth.com">corona@molecularhealth.com</a>
          <br /><br />
          MH Corona Explorer visualizes the mechanisms underlying the SARS-CoV-2 virus in eight interactive screens: 
        </Typography>
        <List>
          <ListItem style={{ whiteSpace: 'nowrap'}}><strong>Organs & Symptoms</strong>: explore a 3D human model for an overview of organs, diseases, and symptoms, and follow links to the underlying biomedical models. </ListItem>
          <ListItem style={{ whiteSpace: 'nowrap'}}><strong>Pathogenic Mechanisms</strong>: explore interactive network diagrams of organs, symptoms, mechanisms, and signaling axes</ListItem>
          <ListItem style={{ whiteSpace: 'nowrap'}}><strong>Disease Model</strong>: understand central mechanisms behind cell damage, even in cells not directly infected by the virus</ListItem>
          <ListItem style={{ whiteSpace: 'nowrap'}}><strong>Expanded Model</strong>: visualizes pathways, molecular mechanisms, and biological systems affected by the virus </ListItem>
          <ListItem style={{ whiteSpace: 'nowrap'}}><strong>Symptomatic Mechanisms</strong>: a list of symptom mechanisms and the associated genes and proteins.</ListItem>
          <ListItem style={{ whiteSpace: 'nowrap'}}><strong>Functional Mechanisms</strong>: a list of mechanisms triggered by the SARS-CoV-2 virus, and the related genes and proteins.</ListItem>
          <ListItem style={{ whiteSpace: 'nowrap'}}><strong>Publications</strong>: Browse over 300 publications underlying the MH Corona Explorer.</ListItem>
          <ListItem style={{ whiteSpace: 'nowrap'}}><strong>Terminology Reference</strong>: this resource helps you to link terminology from the disparate disciplines of biology and medicine.</ListItem>
        </List><br />
        <Typography variant={'h6'} style={{ margin: '0' }}>
          About the aCMDM and Dataome technology
        </Typography>
        <Typography variant={'body1'} style={{ margin: '0',  }}>
          The aCMDM technology leverages Molecular Health’s Dataome platform, designed to enable the continuous capture and curation of globally available sources of clinical and molecular data. MH Dataome integrates knowledge from 100+ public, commercial, and proprietary resources.<br />
          The diverse datasets in MH Dataome include peer-reviewed publications, chemoinformatics, biomedical ontologies, proteins, genes and their annotations, as well as drug labels, targets, interactions, and biomolecular pathways.<br />
          MH applies this information to millions of patient data points from real world data sources and clinical trials in Dataome to build unique solutions for clinical decision-making and knowledge discovery.<br />
        </Typography><br />
        <Typography variant={'h6'} style={{ margin: '0' }}>
          Support
        </Typography>
        <Typography style={{  }}>
          If you have any further questions regarding the MH Corona Explorer, the Corona Mechanisms or Molecular Health, contact us at <a  style={{ textDecoration: 'none', color: 'rgb(128,128, 256)', fontWeight: 'bold'}} href="mailto:corona@molecularhealth.com">corona@molecularhealth.com</a>
        </Typography><br />
        <Typography variant={'body1'} style={{ margin: '0' }}>
          Legal notice
        </Typography>
        <Typography variant={'body2'} style={{ fontSize: '12px'}}>
            The information on this website is provided as an information resource only. <br />
         It is not meant for clinical or commercial use, and is not to be used or relied on for any diagnostic or treatment purposes. <br />
         This information is not intended to be physician (or patient) education, and should not be used as a substitute for professional diagnosis and treatment. 
        </Typography>
        <div style={{ width: '100%' }}>
          <Button onClick={() => history.push('/symptoms')} style={{ background: 'linear-gradient(100deg,#00778b 0%,#97d700 100%)', borderRadius: '32px', textTransform: 'none', color: 'white', margin: '16px 0 32px', paddingLeft: '48px', paddingRight: '48px', fontSize: '1.2em' }}>Start exploring</Button>
        </div>
        </>
        )}
      </Container>
      <div style={{ flex: 1 }} />
    </div>
  </div>
  )
}

export default Overview
