import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

// Disable React Dev Tools on production
// https://github.com/facebook/react-devtools/issues/191
const disableReactDevTools = (): void => {
  const noop = (): void => undefined;
  // tslint:disable-next-line
  const DEV_TOOLS = (window as any).__REACT_DEVTOOLS_GLOBAL_HOOK__;

  if (typeof DEV_TOOLS === 'object') {
    for (const [key, value] of Object.entries(DEV_TOOLS)) {
      DEV_TOOLS[key] = typeof value === 'function' ? noop : null;
    }
  }
};

// Hide React Dev Tools on production environment
if (process.env.NODE_ENV === 'production') {
  disableReactDevTools()
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
