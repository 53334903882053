import React from 'react'
import { AppBar, Divider, Drawer, Hidden, List, ListItem, ListItemText, IconButton, Tabs, Tab, Theme, Toolbar, createStyles, makeStyles, useTheme } from '@material-ui/core'
import MHLogo from './MHLogo'
import CoronaLogo from './CoronaLogo';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useHistory } from 'react-router-dom';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      padding: '.5em 0 0 0',
      backgroundColor: 'white',
      color: '#00263a',
    },
    symptomsTab: {
      padding: '0 .71em',
      textTransform: 'none',
      fontWeight: 400,
      fontSize: '1em',
      height: '3.93em',
      lineHeight: '1.3em',
      minWidth: 63,
      // width: 120,
    },
    keyMechanisms: {
      padding: '0 .71em',
      textTransform: 'none',
      fontWeight: 400,
      fontSize: '1em',
      height: '3.93em',
      lineHeight: '1.3em',
      minWidth: 97,
      // width: 120,
    },
    pathwaysGenes: {
      padding: '0 .71em',
      textTransform: 'none',
      fontWeight: 400,
      fontSize: '1em',
      height: '3.93em',
      lineHeight: '1.3em',
      minWidth: 107,
      // width: 120,
    },
    symptomModels: {
      padding: '0 .71em',
      textTransform: 'none',
      fontWeight: 400,
      fontSize: '1em',
      height: '3.93em',
      lineHeight: '1.3em',
      minWidth: 102,
      // width: 120,
    },
    functionalModels: {
      padding: '0 .71em',
      textTransform: 'none',
      fontWeight: 400,
      fontSize: '1em',
      height: '3.93em',
      lineHeight: '1.3em',
      minWidth: 107,
      // width: 120,
    },
    publicationsTab: {
      padding: '0 .71em',
      textTransform: 'none',
      fontWeight: 400,
      fontSize: '1em',
      height: '3.93em',
      lineHeight: '1.3em',
      minWidth: 73,
      // width: 120,
    },
    tabSelected: {
      color: 'rgb(0, 60, 113)'
    },
    tabsIndicator: {
      backgroundColor: 'rgb(0, 60, 113)',
    },
    mhLogo: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0 1.43em 0 2em',
    },
    bottomBorder: {
      height: '0px',
      width: '100%',
    },
    coronaLogoCt: {
      cursor: 'pointer',
      height: '2em',
      borderLeft: '.07em solid rgba(0,38,58, .2)',
      // borderRight: '.07em solid #00263a',
      padding: '0 .71em 0 1.43em',
      marginTop: '1em'
    },
    drawerCt: {
      display: 'flex'
    },
    smCoronaLogoCt: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      paddingLeft: '1em'
    },
    mhButtonCt: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0 1em 0 1em',
    },
    smMhButtonCt: {
      marginTop: '1em',
      marginBottom: '2em',
    },
    mhLink: {
      padding: '0 0 0 1.43em',
      borderLeft: '.07em solid rgba(0,38,58, .2)',
      color: 'lighten(#003C71, 10%)',
      opacity: 1,
      textDecoration: 'none'
    },
    smMhLink: {
      padding: '0 0 0 1.43em',
      color: 'lighten(#003C71, 10%)',
      opacity: 1,
      textDecoration: 'none'
    },
    // necessary for content to be below app bar
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-start',
    },
  })
);

const a11yProps = (index: any) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const MenuBar = ({ value, onValueChange }: { value: number, onValueChange: (val: number) => void }) => {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const handleChange = (event: null | React.ChangeEvent<{}>, newValue: number) => onValueChange(newValue)
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleListItemClick = (key: number) => {
    setOpen(false);
    onValueChange(key);
  }

  return (<>
    <AppBar classes={{ root: classes.appBar }}>
      <Hidden smDown>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className={classes.mhLogo}>
            <MHLogo />
          </div>
          <div className={classes.coronaLogoCt} title="MH Corona Explorer" onClick={() => {
            history.push('/')
          }} >
            <CoronaLogo height={'2em'} />
          </div>
          <Tabs
            value={value === -1 ? false : value}
            onChange={handleChange}
            aria-label="simple tabs example"
            variant="scrollable"
            scrollButtons="auto"
            classes={{ indicator: classes.tabsIndicator }}
          >
            <Tab label="Organs & Symptoms" classes={{ root: classes.symptomsTab, selected: classes.tabSelected}} {...a11yProps(0)} />
            <Tab label="Pathogenic Mechanisms" classes={{ root: classes.keyMechanisms, selected: classes.tabSelected}} {...a11yProps(1)} />
            <Tab label="Key Mechanism" classes={{ root: classes.keyMechanisms, selected: classes.tabSelected}} {...a11yProps(2)} />
            <Tab label="Expanded Model" classes={{ root: classes.pathwaysGenes, selected: classes.tabSelected}} {...a11yProps(3)} />
            <Tab label="Symptom Mechanisms" classes={{ root: classes.symptomModels, selected: classes.tabSelected}} {...a11yProps(4)} onClick={() => onValueChange(4)} />
            <Tab label="Functional Mechanisms" classes={{ root: classes.functionalModels, selected: classes.tabSelected}} {...a11yProps(5)} onClick={() => onValueChange(5)} />
            <Tab  label="Publications" classes={{ root: classes.publicationsTab, selected: classes.tabSelected }} wrapped {...a11yProps(6)} onClick={() => onValueChange(6)} />
            <Tab  label="Terminology Reference" classes={{ root: classes.publicationsTab, selected: classes.tabSelected }} wrapped {...a11yProps(7)} onClick={() => onValueChange(7)} />
          </Tabs>
          <div style={{ flex: 1 }} />
          <div className={classes.mhButtonCt}>
            <a className={classes.mhLink} target="_blank"rel="noopener noreferrer" href="https://www.molecularhealth.com/">
              molecularhealth.com
            </a>
          </div>
        </div>
        <div className={classes.bottomBorder} />
      </Hidden>
      <Hidden mdUp>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className={classes.mhLogo}>
            <MHLogo />
          </div>
          <div style={{ flex: 1 }} />
          <Toolbar>
            <IconButton
              color="primary"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerOpen}
            >
            <MenuIcon />
            </IconButton>
          </Toolbar>
          <Drawer
            variant="temporary"
            anchor="right"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <div className={classes.drawerCt}>
              <div className={classes.smCoronaLogoCt} title="MH Corona Explorer" onClick={() => {
                history.push('/')
              }} >
                <CoronaLogo height={'2em'} />
              </div>
              <div style={{ flex: 1 }} />
              <div className={classes.drawerHeader}>
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
              </div>
            </div>
            <Divider />
            <List>
              <ListItem button >
                <ListItemText primary={'Organs & Symptoms'} onClick={() => handleListItemClick(0)} />
              </ListItem>
              <ListItem button >
                <ListItemText primary={'Pathogenic Mechanisms'} onClick={() => handleListItemClick(1)} />
              </ListItem>
              <ListItem button >
                <ListItemText primary={'Key Mechanism'} onClick={() => handleListItemClick(2)} />
              </ListItem>
              <ListItem button >
                <ListItemText primary={'Expanded Model'} onClick={() => handleListItemClick(3)} />
              </ListItem>
              <ListItem button >
                <ListItemText primary={'Symptom Mechanisms'} onClick={() => handleListItemClick(4)} />
              </ListItem>
              <ListItem button >
                <ListItemText primary={'Functional Mechanisms'} onClick={() => handleListItemClick(5)} />
              </ListItem>
              <ListItem button >
                <ListItemText primary={'Publications'} onClick={() => handleListItemClick(6)} />
              </ListItem>
              <ListItem button >
                <ListItemText primary={'Terminology Reference'} onClick={() => handleListItemClick(7)} />
              </ListItem>
            </List>
            <Divider />
            <div style={{ flex: 1 }} />
            <div className={classes.smMhButtonCt}>
              <a className={classes.smMhLink} target="_blank"rel="noopener noreferrer" href="https://www.molecularhealth.com/">
                molecularhealth.com
              </a>
            </div>
          </Drawer>
        </div>
      </Hidden>
    </AppBar>
  </>
  )
}

export default MenuBar
