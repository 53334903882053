import React from 'react'
import { Typography } from '@material-ui/core';

const KeyMechanismLegend = () => {
  return (
    <>
      <Typography><strong>Legend</strong></Typography>
      <table style={{ fontFamily: "Arial,Verdana,sans-serif", fontSize: "11pt", width: "100%", borderCollapse: "collapse", border: 0 }}>
        <tbody>
        <tr><td height="5px" colSpan={2}></td></tr>
        <tr>
          <td align="center" valign="top">
            <svg width="110px" height="30px" >
              <g fill="#000000" stroke="#000000" style={{ translate: 'none', transform: 'none' }}>
                <path d="M10 17 L87 17" stroke="black" strokeDasharray="6,6" />
                <path d="M91 17 L79 12 L79 22 Z" stroke="none" />
              </g>
            </svg>
          </td>
          <td align="left" valign="middle">
            Activation
  </td>
        </tr>

        <tr><td height="5px" colSpan={2}></td></tr>
        <tr>
          <td align="center" valign="top">
            <svg width="110px" height="30px" >
              <g stroke="#000000">
                <path d="M10 17 L87 17" fill="none" />
                <path d="M87 8 L88 8 L88 27 L87 27 L87 8 Z" fill="white" stroke="none" />
                <path d="M87 8 L88 8 L88 27 L87 27 L87 8 Z" fill="none" />
              </g>
            </svg>
          </td>
          <td align="left" valign="middle">
            Inhibition
  </td>
        </tr>

        <tr><td height="5px" width="150px" colSpan={2}></td></tr >
        <tr>
          <td align="center" valign="top">
            <svg width="110px" height="30px" >
              <rect x="5" y="5" width="100" height="20" stroke="#00122E" strokeWidth="2" fill="#00245c" rx="2" />
            </svg>
          </td>
          <td align="left" valign="middle">
            Gene
          </td>
        </tr>

      <tr><td height="5px" colSpan={2}></td></tr >
        <tr>
          <td align="center" valign="top">
            <div style={{ width: "100px", height: "34px", background: "#ffffff", border: '1px solid black', borderRadius: '5px', padding: '2px' }}>
              <div style={{ border: '1px solid black', width: '100%', height: '100%', borderRadius: '5px' }}>

              </div>
            </div>
          </td>
          <td align="left" valign="middle">
            Cell membrane
          </td>
        </tr>
        </tbody>
      </table >
    </>
  )
}

export default KeyMechanismLegend
