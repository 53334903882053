import React from 'react'

const CoronaLogo = ({ height }: { height: any}) => {

  return (
    <svg width={String(height)} height={String(height)} viewBox="0 0 566.9 566.9">

      <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="-77.547" y1="325.8523" x2="488.953" y2="325.8523" gradientTransform="matrix(0.3606 -0.9327 0.9327 0.3606 -93.9549 357.7964)">
        <stop  offset="6.017984e-02" style={{stopColor:'#038396'}}/>
        <stop  offset="0.2013" style={{stopColor:'#148A8D'}}/>
        <stop  offset="0.4821" style={{stopColor:'#419C76'}}/>
        <stop  offset="0.8725" style={{stopColor:'#89B850'}}/>
        <stop  offset="1" style={{stopColor:'#A2C243'}}/>
      </linearGradient>
      <path style={{ fill:'url(#SVGID_1_)'}} d="M386.3,19.3C532.7,75.9,605.6,240,549.2,385.9S328.4,604.2,182,547.6S-37.3,326.9,19.1,181
        S239.9-37.3,386.3,19.3z"/>
      <g>
        <g>
          <circle style={{fill:'#FFFFFF', stroke:'#FFFFFF', strokeWidth:1.3, strokeMiterlimit:10}} cx="279.4" cy="102.7" r="22.1"/>
          <line style={{fill:'#FFFFFF', stroke:'#FFFFFF', strokeWidth:15.6006, strokeMiterlimit:10}} x1="279.4" y1="114.4" x2="279.4" y2="185.9"/>
        </g>
        <circle style={{fill:'none', stroke:'#FFFFFF', strokeWidth:15.6006, strokeMiterlimit:10 }} cx="280" cy="285.3" r="107.3"/>
        <g>
          <circle style={{fill:'#FFFFFF', stroke:'#FFFFFF', strokeWidth:1.3, strokeMiterlimit:10}} cx="279.4" cy="469.3" r="22.1"/>
          <line style={{fill:'#FFFFFF', stroke:'#FFFFFF', strokeWidth:15.6006, strokeMiterlimit:10}} x1="279.4" y1="457.6" x2="279.4" y2="386.1"/>
        </g>
        <g>
          <circle style={{fill:'#FFFFFF', stroke:'#FFFFFF', strokeWidth:1.3, strokeMiterlimit:10}} cx="96.1" cy="284.7" r="22.1"/>
          <line style={{fill:'#FFFFFF', stroke:'#FFFFFF', strokeWidth:15.6006, strokeMiterlimit:10}} x1="107.8" y1="284.7" x2="179.3" y2="284.7"/>
        </g>
        <g>
          <circle style={{fill:'#FFFFFF', stroke:'#FFFFFF', strokeWidth:1.3, strokeMiterlimit:10}} cx="462.7" cy="284.7" r="22.1"/>
          <line style={{fill:'#FFFFFF', stroke:'#FFFFFF', strokeWidth:15.6006, strokeMiterlimit:10}} x1="451" y1="284.7" x2="379.5" y2="284.7"/>
        </g>
        <g>
          <circle style={{fill:'#FFFFFF', stroke:'#FFFFFF', strokeWidth:1.3, strokeMiterlimit:10}} cx="150.4" cy="155.5" r="22.1"/>
          <line style={{fill:'#FFFFFF', stroke:'#FFFFFF', strokeWidth:15.6006, strokeMiterlimit:10}} x1="158.6" y1="163.8" x2="209.2" y2="214.4"/>
        </g>
        <g>
          <circle style={{fill:'#FFFFFF', stroke:'#FFFFFF', strokeWidth:1.3, strokeMiterlimit:10}} cx="409.6" cy="414.8" r="22.1"/>
          <line style={{fill:'#FFFFFF', stroke:'#FFFFFF', strokeWidth:15.6006, strokeMiterlimit:10}} x1="401.3" y1="406.5" x2="350.8" y2="355.9"/>
        </g>
        <g>
          <circle style={{fill:'#FFFFFF', stroke:'#FFFFFF', strokeWidth:1.3, strokeMiterlimit:10}} cx="409.6" cy="155.5" r="22.1"/>
          <line style={{fill:'#FFFFFF', stroke:'#FFFFFF', strokeWidth:15.6006, strokeMiterlimit:10}} x1="401.3" y1="163.8" x2="350.8" y2="214.4"/>
        </g>
        <g>
          <circle style={{fill:'#FFFFFF', stroke:'#FFFFFF', strokeWidth:1.3, strokeMiterlimit:10}} cx="150.4" cy="414.8" r="22.1"/>
          <line style={{fill:'#FFFFFF', stroke:'#FFFFFF', strokeWidth:15.6006, strokeMiterlimit:10}} x1="158.6" y1="406.5" x2="209.2" y2="355.9"/>
        </g>
        <g>
          <circle style={{fill:'#FFFFFF', stroke:'#FFFFFF', strokeWidth:1.3, strokeMiterlimit:10}} cx="341" cy="435.3" r="16"/>
          <line style={{fill:'#FFFFFF', stroke:'#FFFFFF', strokeWidth: 10.4004, strokeMiterlimit: 10}} x1="337.7" y1="427.5" x2="317.3" y2="380.1"/>
        </g>
        <g>
          <circle style={{fill:'#FFFFFF', stroke:'#FFFFFF', strokeWidth:1.3, strokeMiterlimit:10}} cx="340.6" cy="136.5" r="16"/>
          <line style={{fill:'#FFFFFF', stroke:'#FFFFFF', strokeWidth: 10.4004, strokeMiterlimit: 10}} x1="337.2" y1="144.3" x2="316.3" y2="191.5"/>
        </g>
        <g>
          <circle style={{fill:'#FFFFFF', stroke:'#FFFFFF', strokeWidth:1.3, strokeMiterlimit:10}} cx="429.9" cy="226.5" r="16"/>
          <line style={{fill:'#FFFFFF', stroke:'#FFFFFF', strokeWidth: 10.4004, strokeMiterlimit: 10}} x1="422" y1="229.5" x2="373.7" y2="247.6"/>
        </g>
        <g>
          <circle style={{fill:'#FFFFFF', stroke:'#FFFFFF', strokeWidth:1.3, strokeMiterlimit:10}} cx="428.6" cy="351.8" r="16"/>
          <line style={{fill:'#FFFFFF', stroke:'#FFFFFF', strokeWidth: 10.4004, strokeMiterlimit: 10}} x1="420.9" y1="348.4" x2="373.6" y2="327.6"/>
        </g>
        <g>
          <circle style={{fill:'#FFFFFF', stroke:'#FFFFFF', strokeWidth:1.3, strokeMiterlimit:10}} cx="216.6" cy="135.9" r="16"/>
          <line style={{fill:'#FFFFFF', stroke:'#FFFFFF', strokeWidth: 10.4004, strokeMiterlimit: 10}} x1="219.8" y1="143.7" x2="239.3" y2="191.5"/>
        </g>
        <g>
          <circle style={{fill:'#FFFFFF', stroke:'#FFFFFF', strokeWidth:1.3, strokeMiterlimit:10}} cx="129.5" cy="226.5" r="16"/>
          <line style={{fill:'#FFFFFF', stroke:'#FFFFFF', strokeWidth: 10.4004, strokeMiterlimit: 10}} x1="137.3" y1="229.6" x2="185.4" y2="248.3"/>
        </g>
        <g>
          <circle style={{fill:'#FFFFFF', stroke:'#FFFFFF', strokeWidth:1.3, strokeMiterlimit:10}} cx="131.3" cy="349.7" r="16"/>
          <line style={{fill:'#FFFFFF', stroke:'#FFFFFF', strokeWidth: 10.4004, strokeMiterlimit: 10}} x1="139.1" y1="346.3" x2="186.4" y2="325.8"/>
        </g>
        <g>
          <circle style={{fill:'#FFFFFF', stroke:'#FFFFFF', strokeWidth:1.3, strokeMiterlimit:10}} cx="215.9" cy="435.2" r="16"/>
          <line style={{fill:'#FFFFFF', stroke:'#FFFFFF', strokeWidth: 10.4004, strokeMiterlimit: 10}} x1="218.6" y1="427.2" x2="235.2" y2="378.3"/>
        </g>
        <g>
          <circle style={{fill:'#FFFFFF', stroke:'#FFFFFF', strokeWidth:1.3, strokeMiterlimit:10}} cx="312.5" cy="250.2" r="11.1"/>
        </g>
        <g>
          <circle style={{fill:'#FFFFFF', stroke:'#FFFFFF', strokeWidth:1.3, strokeMiterlimit:10}} cx="265.7" cy="307.4" r="11.1"/>
        </g>
        <g>
          <circle style={{fill:'#FFFFFF', stroke:'#FFFFFF', strokeWidth:1.3, strokeMiterlimit:10}} cx="240.4" cy="269.1" r="18.2"/>
        </g>
      </g>
    </svg>
  )
}

export default CoronaLogo
