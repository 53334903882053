import { Button } from '@material-ui/core'
import React from 'react'

const QuickGuideDownloadButton = () => {

  return (
    <Button style={{ borderRadius: "50px", height: '24px', margin: 'auto 8px auto 0px', textTransform: 'none', color: 'black', textDecoration: 'none', padding: '0px 8px', whiteSpace: 'nowrap' }} variant={'outlined'}>
      <a href="./MHCoronaExplorer_QuickGuide.pdf" style={{ textDecoration: 'none', color: "black" }} download>
        Explorer Quick Guide
      </a>
    </Button>
  )
}

export default QuickGuideDownloadButton