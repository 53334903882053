import { Button } from '@material-ui/core'
import React from 'react'

const CloseButton = ({ onClose }: { onClose: () => void }) => {
  return (<Button
    style={{
      color: '#FFFFFF',
      background: '#00778b',
      fontWeight: 'bold',
      textTransform: 'none',
      borderRadius: '64px'
    }}
    onClick={onClose}>Close</Button>
  )
}

export default CloseButton