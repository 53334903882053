import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import MenuBar from './MenuBar';
import Overview from './Overview';
import TabPanel, { TabPanelProps } from './TabPanel';
import HelpOutline from '@material-ui/icons/HelpOutline';
import { Typography, Button, Hidden, Dialog, DialogTitle, DialogActions, DialogContent, makeStyles, createStyles, Theme } from '@material-ui/core';
import ModelLegend from './ModelLegend';
import CellModel from './CellModel';
import Mechanisms from './InteractionNetwork/InteractionNetwork'
import Preview from './Preview'
import { FULL_BUILD } from './build';
import CloseButton from './CloseButton';
import Glossary from './Glossary/Glossary';
import GeneHelpTable from './GeneHintTable';
import QuickGuideDownloadButton from './QuickGuideDownloadButton';

const LazyKeyMechanisms = React.lazy(() => import('./KeyMechanisms/KeyMechanisms'))
const KeyMechanisms = () => (
  <React.Suspense fallback={null}>
    <LazyKeyMechanisms />
  </React.Suspense>
)

const LazyBaseModel = React.lazy(() => import('./BaseModel'))
const BaseModel = () => (
  <React.Suspense fallback={null}>
    <LazyBaseModel />
  </React.Suspense>
)

const LazyReferencesTable = React.lazy(() => import('./References/ReferenceTable'))
const ReferencesTable = ({ update }: any) => (
  <React.Suspense fallback={null}>
    <LazyReferencesTable update={update} />
  </React.Suspense>
)

const LazySymptomSubmodelTable = React.lazy(() => import('./Submodels/SubmodelTable'))
const SymptomSubmodelTable = ({ update }: any) => (
  <React.Suspense fallback={null}>
    <LazySymptomSubmodelTable update={update} />
  </React.Suspense>
)

const LazyFunctionalSubmodelTable = React.lazy(() => import('./Submodels/SubmodelTable'))
const FunctionalSubmodelTable = ({ update }: any) => (
  <React.Suspense fallback={null}>
    <LazyFunctionalSubmodelTable update={update} />
  </React.Suspense>
)

const getTabIndexFromLocation = (path: string, search: string) => {
  if (path.indexOf('symptoms') !== -1) {
    return 0
  } else if (path.indexOf('interactions') !== -1) {
    return 1
  } else if (path.indexOf('cellmodel') !== -1) {
    return 2
  } else if (path.indexOf('basemodel') !== -1) {
    return 3
  } else if (path.indexOf('submodels') !== -1) {
    const queryParams = queryString.parse(search);
    if (queryParams.type === 'disease') {
      return 4
    } else if (queryParams.type === 'functional') {
      return 5
    } else {
      return -1
    }
  } else if (path.indexOf('publications') !== -1) {
    return 6
  } else if (path.indexOf('glossary') !== -1) {
    return 7
  } else {
    return -1
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    help: {
      cursor: 'pointer',
      paddingTop: '8px',
      margin: 'auto 5px',
      '&:hover': {
        color: 'rgb(128,128,128)'
      }
    }
  }),
);
const MainNavigation = () => {
  const history = useHistory()
  const location = useLocation()
  const [value, setValue] = React.useState(getTabIndexFromLocation(location.pathname, location.search));
  const [update, setUpdate] = React.useState('')
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const handleDialogClose = () => {
    setShowDialog(false)
  }
  useEffect(() => {
    setValue(getTabIndexFromLocation(location.pathname, location.search))
  }, [location.pathname, location.search])

  const handleChange = (newValue: number) => {
    if (newValue === 0) {
      history.push('/symptoms')
    } else if (newValue === 1) {
      history.push('/interactions')
    } else if (newValue === 2) {
      history.push('/cellmodel')
    } else if (newValue === 3) {
      history.push('/basemodel')
    } else if (newValue === 4) {
      if (location.pathname.indexOf('submodels') !== -1) {
        setUpdate("update");
      } else {
        setUpdate("");
      }
      history.push('/submodels?type=disease')
    } else if (newValue === 5) {
      if (location.pathname.indexOf('submodels') !== -1) {
        setUpdate("update");
      } else {
        setUpdate("");
      }
      history.push('/submodels?type=functional')
    } else if (newValue === 6) {
      if (location.pathname.indexOf('publications') !== -1) {
        setUpdate("update");
      } else {
        setUpdate("");
      }
      history.push('/publications')
    } else if (newValue === 7) {
      history.push('/glossary')
    } else {
      history.push('/')
    }
    setValue(newValue);
  };
  return (
    <>
      <Dialog open={showDialog}>
        <DialogTitle>
          Expanded Model
      </DialogTitle>
        <DialogContent>
          <Typography>
            Biomedical Mechanism ("Expanded Model”) of the SARS-CoV-2 virus, showing related genes and proteins with the corresponding systems and pathways. Signaling Systems and Pathways explain the mechanisms underlying the disease symptoms and the virus infection.
<br /><br />
Click on a gene, then click the Mechanism name to see related symptom and functional Mechanisms details.
          </Typography>
          <br />
          <br />
          <ModelLegend />
          <GeneHelpTable />
        </DialogContent >
        <DialogActions>
          <CloseButton onClose={handleDialogClose} />
        </DialogActions>
      </Dialog >
      <MenuBar value={value} onValueChange={handleChange} />
      <div style={{ height: '62px' }} />
      {
        value !== 0 ?
          <div style={{ height: '6px' }} /> : null
      }
      <KeyMechanismsTab value={value} index={0} />
      <MechanismsTab value={value} index={1} />
      <CellModelTab value={value} index={2} />
      {
        FULL_BUILD ? <>
          <BaseModelTab showDialog={showDialog} setShowDialog={setShowDialog} value={value} index={3} />
          <SymptomSubmodelTab update={update} value={value} index={4} />
          <FunctionalSubmodelTab update={update} value={value} index={5} />
          <ReferencesTab update={update} value={value} index={6} />
          <GlossaryTab update={update} value={value} index={7} />
        </> :
          value > 1 ? <Preview /> : null
      }
      {value === -1 && <Overview />}
    </>
  )
}

const BaseModelTab = ({ setShowDialog, showDialog, ...props }: TabPanelProps & { showDialog: boolean, setShowDialog: (show: boolean) => void }) => {
  const classes = useStyles()
  const [showDescription, setShowDescription] = React.useState<boolean>(false);
  const handleDescriptionClose = () => {
    setShowDescription(false)
  }

  return (
    <TabPanel {...props}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', padding: '0 8px' }}> <Dialog open={showDescription}>
          <DialogTitle>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
            Model Description
          </div>
          </DialogTitle>
          <DialogContent>
          The key players of the seed model converge at the Contact Activation System (CAS): CAS constitutes a group of plasma proteins that are activated by FXII. FXII activates proinflammatory, procoagulant and host defense pathways. The initial step involves activation of prekallikrein (PK). PK reciprocally activates FXII. Inflammation results from the activation of the KKS resulting in the release of kinins, bradykinin (BK) and Kallidin (KD), and the des-Arg-Kinins DABK and DAKD (DAK) from high molecular weight kininogen (HK). Coagulation is driven by FXIIa‐mediated cleavage of FXI, which eventually leads to thrombin activation and clotting. FXII activation also leads to increased plasmin activation, which breaks down the fibrin clot. Finally, FXII can activate C1r and C1s of the complement pathway. SerpinG1 is the key regulator of CAS activation. It is the main inhibitor of the FXIIa, the C1 complex and the conversion of HK to kinins. TMPRSS2 activates tissue kallikrein KLK2, PLAT and fibrinogenic PAR2, which can also be activated by KK. ACE2 metabolizes DAK, thus inhibiting DAK signaling. DAK activates and induces expression of B1R. gC1qR, a pathogen receptor and key activator of the CAS, induces the expression of B1R. B1R activation in turn induces miR200c, which directly targets and suppresses ACE2, constituting a feed-forward loop leading to the generation of excess DAK. miR200c induces Mortalin an inhibitor of the cytotoxic component of the complement cascade. Complement activator MASP2 is a target of the virus N protein. SerpinG1 inhibits MASP2 proteolytic activity. 
          </DialogContent >
          <DialogActions>
            <Button
              style={{
                color: '#FFFFFF',
                background: '#00778B',
                fontWeight: 'bold',
                borderRadius: '50px',
                textTransform: 'none'
              }}
              onClick={handleDescriptionClose}>Close</Button>
          </DialogActions>
        </Dialog >
          <Hidden xsDown>
            <Typography style={{ margin: 'auto' }}>
              Click on a gene, then click the mechanism name to see related symptom and functional mechanism details.
          </Typography>
            <div style={{ flex: 1 }} />
        <Button style={{ borderRadius: "50px", height: '24px', margin: 'auto 0px auto 0px', textTransform: 'none', color: 'black', textDecoration: 'none', padding: '0px 8px', whiteSpace: 'nowrap' }} variant={'outlined'}>
          <a href="./expanded_model.svg" style={{ textDecoration: 'none', color: "black" }} download>
            Download
          </a>
        </Button>
            <Button style={{ borderRadius: "50px", height: '24px', margin: 'auto 8px', textTransform: 'none', color: 'black', textDecoration: 'none', padding: '0px 8px', whiteSpace: 'nowrap' }} variant={'outlined'}
              onClick={() => setShowDescription(true)}
            >
              Model Description
          </Button>
            <QuickGuideDownloadButton />
            <div
              className={classes.help}
              onClick={() => setShowDialog(true)}
            >
              <HelpOutline />
            </div>
          </Hidden>
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', overflow: 'auto' }}>
          <div style={{ flex: 1 }}></div>
          <BaseModel />
          <div style={{ flex: 1 }}></div>
        </div>
      </div>
    </TabPanel>
  )
}

const KeyMechanismsTab = (props: TabPanelProps) => (
  <TabPanel {...props}>
    <KeyMechanisms />
  </TabPanel>
)

const MechanismsTab = (props: TabPanelProps) => (
  <TabPanel {...props}>
    <Mechanisms />
  </TabPanel>
)

const CellModelTab = (props: TabPanelProps) => (
  <TabPanel {...props}>
    <CellModel />
  </TabPanel>
)

const SymptomSubmodelTab = (props: TabPanelProps & { update: string }) => (
  <TabPanel {...props}>
    <SymptomSubmodelTable update={props.update} />
  </TabPanel>
)

const FunctionalSubmodelTab = (props: TabPanelProps & { update: string }) => (
  <TabPanel {...props}>
    <FunctionalSubmodelTable update={props.update} />
  </TabPanel>
)

const ReferencesTab = (props: TabPanelProps & { update: string }) => (
  <TabPanel {...props}>
    <ReferencesTable update={props.update} />
  </TabPanel>
)

const GlossaryTab = (props: TabPanelProps & { update: string }) => (
  <TabPanel {...props}>
    <Glossary />
  </TabPanel>
)

export default MainNavigation
