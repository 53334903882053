import React from 'react'

const useWindowHeight = () => {
  const [height, setHeight] = React.useState<number>(window.innerHeight)

  React.useEffect(() => {
    const handle = () => {
      setHeight(window.innerHeight)
    }
    window.addEventListener('resize', handle)

    return () => {
      window.removeEventListener('resize', handle)
    }
  }, [])

  return height
}

export default useWindowHeight