import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import HelpOutline from '@material-ui/icons/HelpOutline';
import React from 'react';
import CloseButton from './CloseButton';
import QuickGuideDownloadButton from './QuickGuideDownloadButton';

type HelpIconProps = {
  className?: string
  title: string
  body: string | React.ReactNode
}
const Help = ({ className, title, body }: HelpIconProps) => {
  const [showDialog, setShowDialog] = React.useState(false)
  const handleDialogClose = () => {
    setShowDialog(false)
  }
  const handleDialogOpen = () => {
    setShowDialog(true)
  }

  return (
    <>
      <QuickGuideDownloadButton />
      <div
        onClick={handleDialogOpen}
        className={className}
      >
        <HelpOutline />
      </div>
      <Dialog open={showDialog}>
        <DialogTitle>
          {title}
        </DialogTitle>
        <DialogContent>
          {body}
        </DialogContent>
        <DialogActions>
          <CloseButton onClose={handleDialogClose} />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Help