import submodels from './submodels.json'

export const getSubmodels = () => {
  return submodels 
}

export const submodelNumberToName = (id: string) => {
  switch(Number.parseInt(id)) {
    case 45:
      return "anosmia"
    case 50:
      return "coagulation"
    case 63:
      return "autoimmunity"
    case 56:
      return "barrier_permeability"
    case 53:
      return "exocytosis"
    case 55:
      return "inflammation"
    case 61:
      return "mir200c"
    case 60:
      return "crosstalk"
    case 62:
      return "complement_regulation"
    case 58:
      return "ace2_regulation"
    case 57:
      return "ace2_function"
    case 59:
      return "ace2_tmprss2"
    case 52:
      return "vasculitits"
    case 51:
      return "transient_diabetes"
    case 49:
      return "thick_mucus"
    case 48:
      return "silent_hypoxia"
    case 46:
      return "senescence"
    case 47:
      return "nociception"
    case 54:
      return "fibrosis"
    default:
      return "unknown"
  }
}

export const getSubmodelDetailsLink = (id: string) => {
  return `./t/submodels/${id}.html`
}