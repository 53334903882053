import { Typography, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core'
import React from 'react'

const GeneHelpTable = () => {

  return <>
    <Typography style={{ marginTop: '28px'}}><strong>Genes and Molecules</strong></Typography>
    <Table size="small" >
      <TableHead>
        <TableRow>
          <TableCell>
            <strong>
            Used in the Expanded Model view: Molecule or compound
            </strong>
                  </TableCell>
          <TableCell>
            <strong>
            Used in the Expanded Model and other mechanisms
            </strong>
                  </TableCell>
        </TableRow>

      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>
            DABK
                </TableCell>
          <TableCell>
            [des-Arg9]BK
                </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            BDKRB1
                </TableCell>
          <TableCell>
            BR1
                </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            KNG1
                </TableCell>
          <TableCell>
            HK
                </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </>
}

export default GeneHelpTable