import React from 'react'
import { useParams } from 'react-router-dom'
import useWindowHeight from '../useWindowHeight'
import { getSubmodels } from './submodelService'

const SubmodelDetails = () => {
  const { id } = useParams()
  const height = useWindowHeight()
  const submodels = getSubmodels()
  document.title = 'Submodel ' + submodels.find(submodel => submodel.id === id)?.name
  
  return (
    <iframe
      title={`submodel ${id}`}
      style={{ width: '100%', height: `${height - 5}px`, overflow: 'hidden' }}
      src={`templates/${id}.html`}
    />
  )
}

export default SubmodelDetails