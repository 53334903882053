import { createStyles, fade, InputBase, makeStyles, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableRow, Theme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import React from 'react';
import Help from '../Help';
import TableHeader, { getComparator, Order, stableSort } from '../TableHeader';
import glossaryEntries from './glossary.json';
import { useTitle} from '../useTitle'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    row: {
      '&:nth-of-type(odd)': {
        backgroundColor: 'rgba(181,190,197,.4)'
      },
      '&:nth-of-type(even)': {
        backgroundColor: 'rgba(230,234,237,.4)'
      },
      '&:hover': {
        backgroundColor: 'rgba(1,1,1,0.05)'
      },
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    crossIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer'
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.black, 0.05),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.black, 0.15),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
      margin: '4px'
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    help: {
      cursor: 'pointer',
      margin: 'auto 5px',
      '&:hover': {
        color: 'rgb(128,128,128)'
      }
    }
  }),
);

const List = React.memo((props: any) => {
  const classes = useStyles();
  return <>
    {props.rows.length ?
      props.rows.map((row: any, index: number) =>
        <TableRow key={index} className={classes.row}>
          <TableCell>{props.english ? row.organ_english : row.organ_german}</TableCell>
          <TableCell>{props.english ? row.master_term_english : row.master_term_german}</TableCell>
          <TableCell>{props.english ? row.medical_english : row.medical_german}</TableCell>
          <TableCell>{props.english ? row.model : row.model_german}</TableCell>
        </TableRow>
      ) : null
    }
  </>
})

const mapHeaderToField = (header: string, isEnglish: boolean) => {
  switch (header) {
    case 'Organ':
      return isEnglish ? "organ_english" : "organ_german"
    case 'Disease':
      return isEnglish ? "master_term_english" : "master_term_german"
    case 'Symptom / Syndrome':
      return isEnglish ? "medical_english" : "medical_german"
    case 'Mechanism':
      return isEnglish ? "model" : "model_german"
    default:
      return isEnglish ? "organ_english" : "organ_german"
  }
}

const Glossary = ({ update }: any) => {
  useTitle("Terminology Reference")
  const classes = useStyles();
  const [i18n, setI18n] = React.useState(1)
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<any>('Organ');
  const [preparedRows, setPreparedRows] = React.useState<any>(glossaryEntries)

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  React.useEffect(() => {
    setPreparedRows(glossaryEntries
      .filter((g: any) => searchTerm === '' || searchTerm.toLowerCase().split(" ").every(st =>
        JSON.stringify(g).toLowerCase().indexOf(st.trim()) !== -1))
      )
  }, [searchTerm])
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }
  React.useEffect(() => {
    if (update !== '') {
      setSearchTerm('')
    }
  }, [update])
  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row', padding: '0 8px' }}>
        <div style={{ flex: 1 }} />
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={i18n}
          onChange={({ target: { value } }) => setI18n(value as number)}
          autoWidth
        >
          <MenuItem value={0}>German</MenuItem>
          <MenuItem value={1}>English</MenuItem>
        </Select>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            value={searchTerm}
            inputProps={{ 'aria-label': 'search' }}
            onChange={handleSearchChange}
            endAdornment={<div onClick={() => setSearchTerm('')} style={{ visibility: searchTerm.length !== 0 ? 'visible' : 'hidden' }} className={classes.crossIcon}><CloseIcon /></div>}
          />
        </div>
        <Help
          className={classes.help}
          title={'Terminology Reference'}
          body={<>List of Organs, related Symptom / Syndromes and the underlying Mechanisms in English or German.<br /><br />
            Search term is applied to all table columns. By searching for a particular Mechanism, you get all associated Organs and Symptoms or vice versa.
        </>}
        />
      </div>
      <div style={{ flex: 1, overflowY: 'auto' }}>
        <TableContainer component={Paper} style={{ height: '100%', overflowY: 'auto' }}>
          <Table stickyHeader aria-label="glossary of biological and medical symptoms and their correspondences">
            <TableHeader
              headCells={[
                'Organ',
                'Disease',
                'Symptom / Syndrome',
                'Mechanism'
              ]}
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              <List rows={stableSort(preparedRows as any[], getComparator(order, mapHeaderToField(orderBy, i18n === 1)))} english={i18n === 1} />
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}

export default Glossary;
