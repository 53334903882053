import { Button, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, Hidden, makeStyles, Theme, withStyles } from '@material-ui/core'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import { HelpOutline } from '@material-ui/icons'
import React from 'react'
import GeneHelpTable from './GeneHintTable'
import KeyMechanismLegend from './KeyMechanismLegend'
import QuickGuideDownloadButton from './QuickGuideDownloadButton'
import { useTitle } from './useTitle'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    help: {
      cursor: 'pointer',
      paddingTop: '8px',
      margin: 'auto 5px',
      '&:hover': {
        color: 'rgb(128,128,128)'
      }
    }
  }),
)

interface StyledTabProps {
  label: string;
}

interface StyledTabsProps {
  value: number;
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}
const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: 'rgb(20, 97, 179)',
    },
  },
})((props: StyledTabsProps) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      color: '#000',
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(18),
      marginRight: theme.spacing(1),
      '&:focus': {
        opacity: 1,
      },
    },
  }),
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

const CellModel = () => {
  const classes = useStyles()
  useTitle("Key Mechanism")
  const [value, setValue] = React.useState(0)
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  }
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const [showDescription, setShowDescription] = React.useState<boolean>(false);
  const handleDialogClose = () => {
    setShowDialog(false)
  }
  const handleDescriptionClose = () => {
    setShowDescription(false)
  }

  return (
    <div style={{ height: '100%' }}>
      <Dialog open={showDialog}>
        <DialogTitle>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
          Key Mechanism
          </div>
        </DialogTitle>
        <DialogContent>
          <KeyMechanismLegend />
          <GeneHelpTable />
        </DialogContent >
        <DialogActions>
          <Button
            style={{
              color: '#FFFFFF',
              background: '#00778B',
              fontWeight: 'bold',
              borderRadius: '50px',
              textTransform: 'none'
            }}
            onClick={handleDialogClose}>Close</Button>
        </DialogActions>
      </Dialog >  <Dialog open={showDescription}>
        <DialogTitle>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
          {value === 0 ? "Model Description" : "Pathogenesis Description"}
          </div>
        </DialogTitle>
        <DialogContent>
          {
            value === 0 ? <>
              Synopsis of the COVID-19 disease model. <br /><br />
A) The virus targets host cells expressing ACE2 and TMPRSS2, active components of the KKS. <br />
B) Within the cell the virus induces downregulation of SerpinG1 and ACE2. SerpinG1 downregulation induces activation of the CAS and KKS. ACE2 downregulation results in accumulation of Des-Arg-Kinins (DAK). Excess DAK activates B1R, triggering its constitutive activation and auto-amplification. Under normal conditions B1R activation induces a fast, transient inflammatory emergency reaction inducing neutrophil and leukocyte recruitment and infiltration, opening of the epithelial/endothelial barriers for their transmigration, coagulation for local walling-off of the site of inflammation, fibrogenesis for wound healing, senescence as host defense mechanism and transient nociception. Constitutive activation of this system may lead to excess inflammatory signaling, epithelial/endothelial barrier breakdown, induction of thrombosis, fibrosis, tissue repair defects, pain, impairment of exocytosis and induction of autoimmunity. The disease model links eight pathogenic mechanisms to B1R overactivation, providing an explanation for the multifaced systemic nature of COVID-19.<br />
C) B1R signaling induces the formation of MVs bearing B1R and miR200c. These are transferred to target cells, inducing further expression of miR200c, which leads to ACE2 downregulation and again, formation of excess DAK which activates B1R. Thus, MV transfer via auto-activation and amplification of the B1R system may trigger the virus independent propagation of an inflammatory phenotype. This mechanism may explain the long-COVID phenotype and pathogenesis even in the absence of the virus.<br />
            </> : <>
                Schematic time course of COVID-19 lung disease based on lung autopsy findings, from 33033248. This time course is consistent with the proposed model: Early in the disease, an ISGhigh lung profile is observed, with high viral load, high expression of cytokines and ISGs, and sparse immune infiltrates. Late in the disease, an ISGlow lung profile is observed, with low viral load, low local expression of cytokines and ISGs, and strong infiltration of macrophages and lymphocytes. Patients who die early are not able to adequately control SARS-CoV-2, while patients who die late suffer from coagulopathy and immunopathology. This indicates that in a later stage the disease becomes virus-independent.
            </>
          }
        </DialogContent >
        <DialogActions>
          <Button
            style={{
              color: '#FFFFFF',
              background: '#00778B',
              fontWeight: 'bold',
              borderRadius: '50px',
              textTransform: 'none'
            }}
            onClick={handleDescriptionClose}>Close</Button>
        </DialogActions>
      </Dialog >
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row', padding: '0 8px' }}>
        <Hidden xsDown>
          <div style={{ flex: 1 }}></div>
          <div style={{ flex: 1, display: 'flex' }}>
            <div style={{ flex: 1 }}></div>
            <StyledTabs value={value} onChange={handleChange} aria-label="styled tabs example">
              <StyledTab label="Disease Model Synopsis" />
              <StyledTab label="Pathogenesis" />
            </StyledTabs>
            <div style={{ flex: 1 }}></div>
          </div>
          <div style={{ flex: 1, display: 'flex' }}>
            <div style={{ flex: 1 }}></div>
            <Button style={{ borderRadius: "50px", height: '24px', margin: 'auto 8px', textTransform: 'none', color: 'black', textDecoration: 'none', padding: '0px 8px', whiteSpace: 'nowrap' }} variant={'outlined'}
              onClick={() => setShowDescription(true)}
            >
            {value === 0 ? "Model Description" : "Pathogenesis Description"}
          </Button>
            <QuickGuideDownloadButton />
            <div
              className={classes.help}
              onClick={() => setShowDialog(true)}
            >
              <HelpOutline />
            </div>
          </div>
        </Hidden>
      </div>
      <div style={{ height: 'calc(100% - 90px)', width: '100%', display: 'flex', flexDirection: 'row' }}>
        {
          value === 0 ? <>
            <div style={{ flex: 1 }} />
            <img src="./cell_model.svg" style={{ height: '100%' }} alt="" />
            <div style={{ flex: 1 }} />
          </> : <>
              <div style={{ flex: 1 }} />
              <img src="./evidence.svg" style={{ height: '100%' }} alt="" />
              <div style={{ flex: 1 }} />
            </>
        }
      </div>
    </div >
  )
}

export default CellModel