import React from 'react'
import { Typography } from '@material-ui/core';

const ModelLegend = () => {
  return (
    <>
      <Typography><strong>Legend</strong></Typography>
      <table style={{ fontFamily: "Arial,Verdana,sans-serif", fontSize: "11pt", width: "100%", borderCollapse: "collapse", border: 0 }}>
        <tbody>
        <tr><td height="5px" colSpan={2}></td></tr>
        <tr>
          <td align="center" valign="top">
            <svg width="110px" height="30px" >
              <g fill="#000000" stroke="#000000" style={{ translate: 'none', transform: 'none' }}>
                <path d="M10 17 L93 17" fill="none" />
                <path d="M93 17 L81 11 L81 22 Z" stroke="none" />
              </g>
            </svg>
          </td>
          <td align="left" valign="middle">
            Activation - direct
          </td>
        </tr>

        <tr><td height="5px" colSpan={2}></td></tr>
        <tr>
          <td align="center" valign="top">
            <svg width="110px" height="30px" >
              <g fill="#000000" stroke="#000000" style={{ translate: 'none', transform: 'none' }}>
                <path d="M10 17 L87 17" stroke="black" strokeDasharray="6,6" />
                <path d="M91 17 L79 12 L79 22 Z" stroke="none" />
              </g>
            </svg>
          </td>
          <td align="left" valign="middle">
            Activation - indirect
  </td>
        </tr>

        <tr><td height="5px" colSpan={2}></td></tr>
        <tr>
          <td align="center" valign="top">
            <svg width="110px" height="30px" >
              <g stroke="#000000">
                <path d="M10 17 L87 17" fill="none" />
                <path d="M87 8 L88 8 L88 27 L87 27 L87 8 Z" fill="white" stroke="none" />
                <path d="M87 8 L88 8 L88 27 L87 27 L87 8 Z" fill="none" />
              </g>
            </svg>
          </td>
          <td align="left" valign="middle">
            Inhibition - direct
  </td>
        </tr>

        <tr><td height="5px" colSpan={2}></td></tr>
        <tr>
          <td align="center" valign="top">
            <svg width="110px" height="30px" >
              <g stroke="#000000" fill="#ffffff">
                <path d="M10 17 L87 17" stroke="black" strokeDasharray="6,6" />
                <path d="M87 8 L88 8 L88 27 L87 27 L87 8 Z" fill="white" stroke="none" />
                <path d="M87 8 L88 8 L88 27 L87 27 L87 8 Z" fill="none" />
              </g>
            </svg>
          </td>
          <td align="left" valign="middle">
            Inhibition - indirect
  </td>
        </tr>

        <tr><td height="5px" colSpan={2}></td></tr>
        <tr>
          <td align="center" valign="top">
            <svg width="110px" height="30px" >
              <g fill="#000000" stroke="#000000" style={{ translate: 'none', transform: 'none' }}>
                <path d="M10 17 L78 17" fill="none" />
                <circle cx="83" cy="17" r="6" stroke="black" strokeWidth="1" fill="none" />
              </g>
            </svg>
          </td>
          <td align="left" valign="middle">
            Catalysis Conversion
  </td>
        </tr>

        <tr><td height="5px" width="150px" colSpan={2}></td></tr >
        <tr>
          <td align="center" valign="top">
            <svg width="110px" height="30px" >
              <rect x="5" y="5" width="100" height="20" stroke="#00122E" strokeWidth="2" fill="#00245c" rx="2" />
            </svg>
          </td>
          <td align="left" valign="middle">
            Gene
          </td>
        </tr>

        <tr><td height="5px" colSpan={2}></td></tr >
        <tr>
          <td align="center" valign="top">
            <svg style={{ width: "110px", height: "40px", background: "#ffffff" }}>
              <rect id="path-9" x="0" y="0" width="108" height="26" rx="13"></rect>
              <filter x="-6.5%" y="-19.2%" width="113.0%" height="153.8%" filterUnits="objectBoundingBox" id="filter-10">
                <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"></feComposite>
                <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
              </filter>

              <g id="Rectangle">
                <use fill="black" fillOpacity="1" filter="url(#filter-10)" href="#path-9"></use>
                <rect stroke="#00122E" strokeWidth="2" strokeLinejoin="miter" fill="#FFFFFF" fillRule="evenodd" cx="55" cy="20" rx="12" x="1" y="1" width="106" height="24"></rect>
              </g>
            </svg>
          </td>
          <td align="left" valign="middle">
            Molecule
  </td>
        </tr><tr><td height="5px" colSpan={2}></td></tr >
        <tr>
          <td align="center" valign="top">
            <div style={{ width: "100px", height: "34px", background: "#ffffff", border: '1px solid black', borderRadius: '5px', padding: '2px' }}>
              <div style={{ border: '1px solid black', width: '100%', height: '100%', borderRadius: '5px' }}>

              </div>
            </div>
          </td>
          <td align="left" valign="middle">
            Cell membrane
          </td>
        </tr>
        </tbody>
      </table >
    </>
  )
}

export default ModelLegend
